<template>
  <div class="fs-14 teamServer" style="overflow-y: auto; width: 100%; height: 100%;padding: 1%;box-sizing: border-box;padding: 20px;">
    <div class="" @click="$router.go(-1)">返回</div>
    <h2>免责声明</h2>
    <p>更新时间：2023年3月01日</p>
    <p>生效时间：2023年3月01日</p>
    <p>在使用本网站提供的 AI 服务前，请您务必仔细阅读并理解本《免责声明》（以下简称“本声明”）。请您知悉，如果您选择继续访问本网站、或使用本网站提供的本服务以及通过各类方式利用本网站的行为（以下统称“本服务”），则视为接受并同意本声明全部内容。</p>
    <p>服务仅供个人学习、学术研究目的使用，未经许可，请勿分享、传播输入及生成的文本、图片内容。您在从事与本服务相关的所有行为(包括但不限于访问浏览、利用、转载、宣传介绍)时，必须以善意且谨慎的态度行事；您确保不得利用本服务故意或者过失的从事危害国家安全和社会公共利益、扰乱经济秩序和社会秩序、侵犯他人合法权益等法律、行政法规禁止的活动，并确保自定义输入文本不包含以下违反法律法规、政治相关、侵害他人合法权益的内容：</p>
    <p>1.反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>2.损害国家荣誉和利益的；</p>
    <p>3.歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；</p>
    <p>4.宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
    <p>5.煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>6.破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>7.散布谣言，扰乱经济秩序和社会秩序的；</p>
    <p>8.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>9.侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；</p>
    <p>10.含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容</p>
    <p>11、中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容。</p>
    <p>12.您确认使用本服务时输入的内容将不包含您的个人信息。您同意并承诺，在使用本服务时，不会披露任何保密、敏感或个人信息。</p>
    <p>13.您确认并知悉本服务生成的所有内容都是由人工智能模型生成，所以可能会出现意外和错误的情况，请确保检查事实。我们对其生成内容的准确性、完整性和功能性不做任何保证，并且其生成的内容不代表我们的态度或观点，仅为提供更多信息，也不构成任何建议或承诺。对于您根据本服务提供的信息所做出的一切行为，除非另有明确的书面承诺文件，否则我们不承担任何形式的责任。</p>
    <p>14.本服务来自于法律法规允许的包括但不限于公开互联网等信息积累，因互联网的开放性属性，不排除其中部分信息具有瑕疵、不合理或引发不快。遇有此情形的，欢迎添加客服举报。</p>
    <p>15.不论在何种情况下，本网站均不对由于网络连接故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令，黑客攻击，互联网病毒，网络运营商技术调整，政府临时管制或任何其他不能合理控制的原因而造成的本服务不能访问、服务中断、信息及数据的延误、停滞或错误，不能提供或延迟提供服务而承担责任。</p>
    <p>16.当本服务以链接形式推荐其他网站内容时，我们并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性。在法律允许的范围内，本网站不承担您就使用本服务所提供的信息或任何链接所引致的任何直接、间接、附带、从属、特殊、继发、惩罚性或惩戒性的损害赔偿。</p>
    <p>17.本服务所创作的AI内容属于人工智能深度合成内容，将严格遵守国家《互联网信息服务深度合成管理规定》所示规定，仅供参考，请谨慎使用，建议进行二次审核与修改，如发生任何商业争议或不当言论，其责任由所使用的用户承担，且内容所述也不代表本公司观点和立场。</p>
    <p>18.本服务用户，对其基于产品创作的内容素材具有使用权，同时用户应自觉遵守《文明上网自律公约》，不使用淫秽、暴力、不良政治引导等内容作为AI创作的引导内容。用户创作内容并不代表本公司赞同其观点和对其真实性负责，用户需对创作素材在商用过程中所引致的争议负责。</p>
    <h2>服务协议</h2>
    <div>助手AI是由北京祥运网科技有限公司（下称“祥运网”）提供的互联网软件服务。本服务条款（下称“服务条款”）是您与祥运网之间关于您（“您”或“用户”）访问和使用助手AI以及祥运网提供的其他服务（下称“服务”）的主要协议。您注册、登录助手AI和/或使用祥运网提供的服务，即表示您同意接受服务条款，如您不同意该协议，请不要使用本服务。因此，敬请仔细阅读。
    祥运网有权不时地对服务条款做任何修改和补充，并在助手AI网站上公布。服务条款的修改和补充将在公布时立即生效。您继续访问和使用助手AI即视为您接受修订后的服务条款。如您不同意修订后的服务条款，您应停止访问助手AI并拒绝使用服务或删除您在助手AI上的信息和帐户来终止接受服务。
    如果您代表某个机构而非您个人注册、登录和使用助手AI和/或我们其他的服务，则您将被认为获得充分授权代表该机构同意本服务条款以及不时修订和补充的服务条款。</div>
    <h3>1.服务内容</h3>
    <div>为不断提升用户体验，祥运网会不时为用户提供其他服务内容，届时，可能与您另行签订其他专项协议。但即使未另行签订其他专项协议，您使用助手AI的具体服务时，也将被视为您同意祥运网关于该等具体服务的任何要求。如果其他专项协议和本条款之间存在冲突，应以其他专项协议为准，但以该冲突和与该协议特定事宜相关为限。
    祥运网保留随时变更、中止或终止免费服务的权利，并保留根据实际情况随时调整助手AI提供的服务种类、形式的权利。祥运网不承担因助手AI提供的任何免费服务的调整给您造成的损失。尽管有本条约定，祥运网有权在未来恰当的时机对该等免费服务内容收取相应的服务费用。祥运网保留随时终止向您提供的收费服务的权利，并保留根据实际情况随时调整助手AI提供的收费服务种类和形式的权利。如果祥运网终止提供某项收费服务，祥运网的义务仅在于向您返还您尚未使用的服务期对应的部分费用。但无论如何，祥运网将尽合理的努力为您预留合理的时间以便您为该等服务的变更、中止或终止做出应对。
    </div>
    <h3>2.注册</h3>
    <div>为了能访问助手AI和使用服务，您同意以下事项：依助手AI网站提示填写准确的、真实的注册邮箱、手机号、密码和名称，并确保今后更新的登录邮箱、名称、头像等资料的有效性和合法性。若您提供任何违法、虚假、不道德或祥运网认为不适合在助手AI上展示的资料；或者祥运网有理由怀疑您的资料属于病毒程序或恶意操作；或者您违反本服务条款的规定；或者未经祥运网同意，将助手AI用于商业目的，祥运网有权暂停或终止您的帐号，并拒绝您于现在和未来使用服务之全部或任何部分。
    尽管有前述规定，祥运网无义务对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、准确性、完整性、适用性及/或是否为最新资料的责任。同时，祥运网建议您妥善保管您的注册邮箱、手机号、密码和名称，准确输入该等信息将作为您访问登录助手AI并享有服务的关键环节。如果您发现有人未经授权使用您的帐户信息或怀疑任何人在未经授权的情况下，能够访问您的私有内容，您应立即更改密码，并向我们反馈。如果您提供的信息不正确或您未能确保您的帐户信息的安全而造成任何损失或损害，祥运网不承担任何责任。
    </div>
    <h3>3.会员服务</h3>
    <div>助手AI会员服务（“会员服务”）是您支付会员服务费后在会员有效时长内享有包括“不限文件数量”等服务在内的服务产品。您可以选择购买多种类型助手AI的会员服务，在依约支付一定费用后开通会员服务。会员服务开通成功后，您可在会员有效时长内享受相应会员权益。祥运网会根据用户的需求、产品的变化等，对现有会员服务内容进行调整。祥运网也会在不同时间推出具体不同的会员服务内容，以不断完善、优化会员服务。具体会员服务内容以【“价格”】页面公布、实际提供的内容为准。
    会员服务包括短期会员及终身会员（100年）类型，短期会员指开通会员服务后在相应会员期限内，会员可以不受次数限制地使用会员服务内容，终身会员（100年）是指在开通会员服务后100年内，会员可以不受次数限制地使用会员服务内容。您享有的会员服务的服务期限以您自行选择并支付相应服务费用的期限为准，您可以登陆【“价格”】页面查询。您选择使用会员服务后，您有权随时停止使用会员服务，但祥运网不以任何方式对您所支付的费用予以退还，除非祥运网停止提供会员服务。
    禁止通过以下方式开通或使用会员服务： </div>
    <h5>(1)以营利、经营等非个人使用的目的为自己或他人开通会员服务；</h5>
    <h5>(2)通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通会员服务；</h5>
    <h5>(3)通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通会员服务；</h5>
    <h5>(4)通过非祥运网指定的方式为自己或他人开通会员服务；</h5>
    <h5>(5)通过侵犯祥运网或他人合法权益的方式为自己或他人开通会员服务；</h5>
    <h5>(6)通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通会员服务。</h5>
    <div>祥运网会根据会员服务的整体规划，对会员服务的服务内容、收费标准、方式等进行修改和变更，前述修改、变更，祥运网将在相应服务页面进行公示。您若需要获取、使用会员服务，请先提前了解清楚当时关于会员服务的收费标准、方式等信息。</div>
    <h3>4.内容发布以及使用权</h3>
    <h4>4.1内容的发布</h4>
    <div>用户在助手AI上发布的内容（包含但不限于助手AI目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表祥运网的立场或观点。作为内容的发布者，您对在本平台上传、发布的内容承担全部责任，与祥运网无关。祥运网无法控制经由本平台上传、发布的内容，因此不保证上传、发布的内容的正确性、合法性、安全性、完整性。尽管祥运网有权对您上传至助手AI的数字作品进行审核，但该审核仅限于形式审核，不对您上传的内容作任何实质性审核，且祥运网的上述审核行为不视为祥运网对于您使用本平台所上传、发布的内容承担任何保证责任。如您使用本平台上传的内容违反法律规定或侵害到其他人合法权益导致任何第三人提出索赔的，均由您独立承担全部法律责任。
    如若祥运网有理由认为您使用本服务的行为违反法律规定或有任何第三方通知祥运网您使用本服务的行为侵犯其合法权益的，祥运网有权暂停或终止您使用本服务的全部或部分，即便您使用本服务的行为有可能最终未被有管辖权的司法机构认定为非法或侵犯任何第三方的合法权益。
    您认为其他用户在助手AI上发表的内容侵犯您的合法权利，您有权向祥运网提交书面通知（该书面通知应当包含下列内容：（一）您的姓名、联系方式和地址；（二）要求删除或者断开链接的内容的名称和网络地址；（三）构成侵权的初步证明材料；（四）对侵权行为的简要描述。如果您代表所属单位、机构，还请提交委托证明；该书面通知包含的每页内容您均应亲笔签名或加盖公司印章，您可以将上述书面通知的电子扫描件文档发送至祥运网指定电子邮箱【助手AI@92Lucky.com】
    祥运网将在收到您提交的书面通知后下架您在书面通知书里要求取消公开的涉嫌侵权的内容或断开与该等涉嫌侵权内容的链接并将您的通知书的内容通过邮件的方式转送给涉嫌侵权的版权方，即为祥运网履行完毕向涉嫌侵权的版权方转送该通知书的义务。
    如本款上述涉嫌侵权的版权方认为祥运网依据您的通知书删除或断开链接的涉嫌侵权的内容未侵犯他人权利的，其有权向祥运网提交书面说明，要求恢复被下架的内容或者恢复与被断开的内容的链接，祥运网将在接到其书面说明后恢复被下架的内容或者恢复与被断开的内容的链接，同时将该书面说明通过邮件的方式转送给您。祥运网上述下架内容与恢复内容的行为是在用户的申请下进行的，并非祥运网独立做出的相关决定，关于侵权方与被侵权方之间的纠纷，由双方独立解决，祥运网作为平台方仅负有沟通、协调的义务，不承担双方之间的任何侵权责任。
    用户不得使用助手AI服务发送或传播敏感信息和违反国家法律制度的信息，包括但不限于下列信息：</div>
    <h5>(1)反对宪法所确定的基本原则的；</h5>
    <h5>(2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</h5>
    <h5>(3)损害国家荣誉和利益的；</h5>
    <h5>(4)煽动民族仇恨、民族歧视，破坏民族团结的；</h5>
    <h5>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</h5>
    <h5>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</h5>
    <h5>(7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</h5>
    <h5>(8)侮辱或者诽谤他人，侵害他人合法权益的；</h5>
    <h5>(9)含有法律、行政法规禁止的其他内容的。</h5>
    <div>用户承诺发表言论要：爱国、守法、自律、真实、文明。不传播任何非法的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、危害国家安全的、泄露国家机密的、破坏国家宗教政策和民族团结的以及其它违反法律法规及政策的内容。若用户的行为不符合以上提到的服务条款，助手AI将作出独立判断立即暂停或终止用户的服务帐号。用户需对自己在网上的行为承担法律责任，助手AI不承担任何法律责任及连带责任。
    关于付费模板</div>
    <h5>(1)用户发布的付费模板在系统审核通过后会自动公开到助手AI，并供其他用户付费使用；</h5>
    <h5>(2)付费模板在助手AI平台上完成交易后，成交额的30%作为平台服务费（含税），其余的70%作为作者收益计入到个人账户、并可提现；</h5>
    <h5>(3)用户将付费模板或免费模板公开到助手AI后，授权助手AI在第三方平台进行模板推广。现处于推广阶段，助手AI不对第三方模板市场分发定价、不进行作者分成。</h5>
    <h4>4.2内容的使用权</h4>
    <div>用户将文件发布并公开到助手AI后，即视为永久授权助手AI使用，并授权祥运网将文件通过互联网及/或移动互联网面向全球用户进行发布、推广以及传播，以及以任何合法方式将文件用于对助手AI平台的宣传、推广。若用户上传的文件涉及到个人隐私及商业秘密应在文件中予以注明，对于未注明的，视为未涉及个人隐私及商业秘密，助手AI可以不做任何标记直接用于宣传及推广展示使用，若因此发生纠纷，助手AI不承担任何法律责任。
      助手AI平台的模板使用，无论是使用平台自有模板，还是其他用户上传的付费模板，任何时候您均不得将通过前述服务获得的有权使用的模板的任何部分或全部以转授权、分授权或其他任何方式免费的或收费的提供给他人（包括自然人及具备或不具备法人主体资格的机构、组织）使用，亦不得利用有权使用的数字作品的任何部分或全部以销售、租赁或变相销售、租赁或其他类似的方式牟利。</div>
    <h3>5.版权</h3>
    助手AI的外观设计、计算机代码与专利等均归祥运网所有。未经祥运网事先书面许可，您不能复制、拷贝、或者使用任何部分的代码和外观设计。
    <h3>6.隐私政策</h3>
    <div>使用助手AI和祥运网提供的服务，即表示您同意祥运网合法收集和使用有关您及您所使用服务的技术性或诊断性信息。收集到的这些信息将用于改进助手AI产品的内容和技术，提升助手AI的服务品质。</div>
    <div>祥运网不会将您的信息和内容提供或出售给其他的组织或个人，但以下情况除外：</div>
    <h5>（1）您的事先同意或授权，或您于助手AI上主动与第三方进行分享操作；</h5>
    <h5>（2）司法机关或有权政府机构或任何法律法规部门规章要求祥运网提供该等信息；</h5>
    <h5>（3）您违反了本服务条款，且祥运网需要向第三方提供的。</h5>
    <h3>7.数据安全</h3>
    <div>祥运网将尽合理的努力保护您的信息安全，并为此采取合理的数据传输、存储、转换等预防保护措施。但是，互联网数据传输、存储、转换均可能存在一定未知且不确定的数据安全风险，该等风险将导致包括但不限于数据丢失、泄露、损坏、无法读取或提取等后果。您确认，您已明确知晓并同意接受该等因互联网引发的风险和后果，并已采取恰当的措施（例如数据备份等），以便在该等风险发生时将损失降至最低。
      因互联网技术本身等非祥运网主观故意或重大过失导致危害您数据安全的，祥运网不承担任何赔偿责任。因祥运网重大过失危害您数据安全的，祥运网的赔偿责任以向您收取的服务费用为上限。</div>
    <h3> 8.免责声明</h3>
    <div> 一旦您注册成为用户即表示您与祥运网达成协议，完全接受本服务条款项下的全部条款。对免责声明的解释、修改及更新权均属于祥运网所有。</div>
    <h5>（1）由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因祥运网原因导致的个人信息的泄漏，祥运网不承担任何法律责任；</h5>
    <h5>（2）任何第三方根据祥运网各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷，祥运网不承担任何法律责任以及连带责任；</h5>
    <h5>（3）任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭，祥运网不承担任何法律责任；</h5>
    <h5>（4）我们鼓励用户充分利用助手AI自由地发布和共享自己的信息。您可以自由发布文字、图片等内容，但这些内容必须位于公共领域内，或者您拥有这些内容的使用权。同时，用户不应在自己的个人主页或助手AI的任何其他地方发布受版权保护的内容；</h5>
    <h5>（5）用户在助手AI发布侵犯他人知识产权或其他合法权益的内容，祥运网有权予以删除，并保留移交司法机关处理的权利；</h5>
    <h5>（6）用户对于自己创作并在助手AI上发布的合法作品依法享有著作权及其他相关权利；</h5>
    <h5>（7）互联网是一个开放平台，用户将图片等资料上传到互联网上，有可能会被其他组织或个人复制、转载、擅改或做其它非法用途，用户必须充分意识到此类风险的存在。用户明确同意使用祥运网服务所存在的风险将完全由用户自己承担；因用户使用祥运网服务而产生的一切后果也由用户自己承担，祥运网对用户不承担任何责任。</h5>
    <h3>9.服务变更/中断/终止</h3>
    如因系统维护或升级的需要而暂停网络服务、调整服务功能的，祥运网将尽可能事先在网站上进行通告。
    如发生下列任何一种情形，祥运网有权单方面中断或终止向用户提供服务而无需通知用户：
    <h5>（1）用户提供的个人资料不真实；</h5>
    <h5>（2）用户违反本服务条款中规定的使用规则；</h5>
    <h5>（3）未经祥运网同意，将助手AI用于商业目的。</h5>
    <h5>（4）禁止在未经预先书面授权的情况下销售或转卖祥运网的付费服务（包括但不限于会员权益和文件扩容数）。如果发现存在未经祥运网预先书面授权囤积、倒卖、转售祥运网产品的行为，祥运网有权追究其的法律责任。同时，祥运网有权调查任何通过不正当手段获取，会员权益、文件扩容数及兑换码的行为，一经发现，祥运网有权收回用户已获得的奖励、权益，情节严重的，祥运网将冻结其账号或者封号，祥运网有权采用以上一种或多种的方式对违规用户进行处理。</h5>
    <h3>10.注销账号</h3>
    <div>您有权通过注销账号的方式终止本协议，您可以通过“个人头像-账户中心-账号注销”完成账号注销。账号一旦注销将不可恢复，请您在操作之前自行备份账号相关的所有信息和内容，账号注销后，您将无法继续使用该账号，也无法找回该账号中的任何内容或信息，除法律有明确规定外，祥运网无义务向您或您指定的第三方披露您账号中的任何内容或信息。</div>
    <div>您注销账号后，祥运网仍享有下列权利：</div>
    <h5>（1）继续保存您留存于助手AI的各类信息，但根据适用法律的要求删除或匿名化处理您的个人信息；</h5>
    <h5>（2）对于您过往的违约行为，祥运网仍可依据本协议向您追究违约责任。</h5>
    <h3>11.服务条款的完善和修改</h3>
    <div>祥运网有权根据互联网行业变化以及有关法律、法规的颁布、修订，不时地完善和修改祥运网服务条款。祥运网保留随时修改服务条款的权利，用户在使用祥运网的服务时，有必要对祥运网最新的服务条款进行仔细阅读和重新确认，当发生相关争议时，以最新的服务条款为准。</div>
    <h3>12.特别约定</h3>
    <div>本服务条款及其下的服务受中华人民共和国法律管辖，并按之解释。</div>
    <div>用户使用本服务的行为若有任何违反国家法律法规或侵犯任何第三方合法权益的情形，祥运网有权直接删除该等违反规定的信息，并可以暂停或终止向该用户提供服务。</div>
    <div>若用户利用本服务从事任何违法或侵权行为，由用户自行承担全部责任，祥运网不承担任何法律责任及连带责任。因此给祥运网或任何第三方造成任何损失的，用户应负责全额赔偿。</div>
    <div>用户在此特别声明并承诺，用户已充分注意本服务协议内免除或限制祥运网责任的条款，用户完全知晓并理解该等条款的规定并同意接受。</div>
    <h3>13.联系我们</h3>
    <div>欢迎您对我们的服务条款提出意见。如有任何问题、意见或疑虑，请发邮件至AI@92Lucky.com此电子邮件地址作为本服务条款的组成部分可能会不时进行更新。</div>
    <div>©北京祥运网科技有限公司保留一切权利。</div>
  </div>
</template>
<style>
  @media only screen and (min-width: 800px) {
    .teamServer{
      width: 740px!important;
      margin: 0 auto;
    }
  }
</style>
